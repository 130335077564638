import * as React from "react"
import { useState } from "react"
import { AnimatePresence } from "framer-motion"
import FormPopup from "../components/popups/FormPopup"
import VideoPopup from "../components/popups/VideoPopup"
import GalleryPopup from "../components/popups/GalleryPopup"
import EnrollCourse from "../components/offers/EnrollCourse"
import VideoBox from "../components/VideoBox"
import { STRAPI_API } from "../consts/api"
import Title from "../components/Title"
import SliderImages from "../components/sliders/SliderImages"
import TextGlassCards from "../components/cards/TextGlassCard"
import DoubleBlockHead from "../components/offers/DoubleBlockHead/DoubleBlockHead"
import DoubleBlock from "../components/doubleBlock/DoubleBlock"
import SidebarMenuServices from "../components/sideBarMenu/SidebarMenuServices"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import {SEO} from "../components/seo";

const Promo = ({ data, location }) => {
  const { strapiPromoVideoPage } = data

  const [formPopup, setFormPopup] = useState(false)

  const [popUpVideo, setPopUpVideo] = useState(false)
  const [videoSrc, setVideoSrc] = useState("")

  const [popUpGallery, setPopUpGallery] = useState(false)
  const [galleryElem, setGalleryElem] = useState(false)

  const mapOfferText = data => {
    return {
      title: data.title,
      inputHeader: data.inputHeader,
      price: data.price,
      subTitle: data.priceForStudent,
      text: data.subtitle,
    }
  }

  const mapTextData = data => {
    return [
      {
        title: data.item1.title,
        subtitle: data.item1.subtitle,
      },
      {
        title: data.item2.title,
        subtitle: data.item2.subtitle,
      },
    ]
  }

  return (
    <div>
      <SEO title={strapiPromoVideoPage?.titleDesc?.pageTitle} description={strapiPromoVideoPage?.titleDesc?.pageDescription}/>
      <Layout classAdd="familyPage" location={location}>
        <DoubleBlock menu={<SidebarMenuServices setFormPopup={setFormPopup} />}>
          {
            // MAIN OFFER
            strapiPromoVideoPage?.offer && (
              <DoubleBlockHead
                setFormPopup={setFormPopup}
                data={strapiPromoVideoPage.offer}
              />
            )
          }

          {
            //TEXT CARD
            strapiPromoVideoPage?.item1 && (
              <TextGlassCards
                classAdd="textCard--two textCard--bigText m60_40"
                data={mapTextData(strapiPromoVideoPage)}
              />
            )
          }

          {
            // PHOTO SLIDER
            strapiPromoVideoPage?.slider && (
              <div className="m60_40">
                <SliderImages
                  data={strapiPromoVideoPage?.slider}
                  positionCenter={false}
                  setOpen={setPopUpGallery}
                  open={popUpGallery}
                  setGalleryElem={setGalleryElem}
                />
              </div>
            )
          }

          {strapiPromoVideoPage?.video?.url && (
            <Title
              value={[
                ["red", "Pioneer DJ "],
                ["dark", ``],
              ]}
              classAdd="principle--title m140"
            />
          )}

          {strapiPromoVideoPage?.video?.url && (
            <div className="m140">
              <VideoBox
                videSrc={STRAPI_API + strapiPromoVideoPage?.video?.url}
                coverImg={
                  strapiPromoVideoPage?.video_cover?.url
                    ? STRAPI_API + strapiPromoVideoPage?.video_cover?.url
                    : null
                }
                setVideoSrc={setVideoSrc}
                setPopUpVideo={setPopUpVideo}
              />
            </div>
          )}

          {
            // OFFER 2
            strapiPromoVideoPage?.offer2 && (
              <EnrollCourse
                classAdd="m140"
                data={mapOfferText(strapiPromoVideoPage.offer2)}
              />
            )
          }
        </DoubleBlock>
      </Layout>

      <AnimatePresence
        initial={false}
        exitBeforeEnter={true}
        onExitComplete={() => null}
      >
        {popUpGallery &&
          data?.promoVideoPage?.data?.attributes?.slider?.data && (
            <GalleryPopup
              open={popUpGallery}
              setOpen={setPopUpGallery}
              galleryElem={galleryElem}
              data={data?.promoVideoPage.data.attributes.slider.data}
            />
          )}

        {formPopup && <FormPopup open={formPopup} setOpen={setFormPopup} />}

        {popUpVideo && videoSrc && (
          <VideoPopup
            open={popUpVideo}
            setOpen={setPopUpVideo}
            videoSrc={videoSrc}
            setVideoSrc={setVideoSrc}
          />
        )}
      </AnimatePresence>
    </div>
  )
}

export const pageQuery = graphql`
  query {
    strapiPromoVideoPage {
      offer2 {
        button
        title
        subtitle
        price
        priceForStudent
        inputHeader
      }
      item1 {
        title
        subtitle
      }
      offer {
        button
        subtitle
        title
      }
      item2 {
        title
        subtitle
      }
      slider {
        url
      }
      titleDesc {
        pageTitle
        pageDescription
      }
    }
  }
`

export default Promo
